//4th year
import icon41 from "../../Assets/Teammembers/24356 - Parv Sarin.jpeg";
import icon42 from "../../Assets/Teammembers/20230502_162439 - Keshav Mittal.jpg";
import icon43 from "../../Assets/Teammembers/20230601_184004 - Nishchal.jpg";
import icon44 from "../../Assets/Teammembers/20230707191932_IMG_6831 - Bhupeksh _Kaushik.jpg";
import icon45 from "../../Assets/Teammembers/garima.jpg";
import icon46 from "../../Assets/Teammembers/IMG_0476 - Tanuj _6145.jpeg";
import icon47 from "../../Assets/Teammembers/IMG_2790 - Sourabh Singh.jpeg";
import icon48 from "../../Assets/Teammembers/IMG_4340 - ME_C_09_170_ Deekshith Naik.jpeg";
import icon410 from "../../Assets/Teammembers/IMG_5310-2 - ABHISHEK KUMAR.jpg";
import icon411 from "../../Assets/Teammembers/IMG_20220201_140147_114 - Mysterious gamer.jpg";
import icon412 from "../../Assets/Teammembers/IMG_20230625_022008 - 018_EE-A2_Navneet Raj.jpg";
import icon413 from "../../Assets/Teammembers/abinaya.jpg";
import icon414 from "../../Assets/Teammembers/IMG_20230719_125932 - Kritika Agrawal.jpg";
import icon415 from "../../Assets/Teammembers/IMG_20230719_133000 - muskan bidani.jpg";
import icon416 from "../../Assets/Teammembers/IMG-20221012-WA0010 - Lisha Garg.jpg";
import icon417 from "../../Assets/Teammembers/IMG-20230716-WA0052 - Ani Dubey.jpg";
import icon418 from "../../Assets/Teammembers/IMG20220611104150 - 6102 Koilada bindu madhava.jpg";
import icon419 from "../../Assets/Teammembers/SAVE_20230713_011735 - Hima.jpg";
import icon420 from "../../Assets/Teammembers/Himanshu Khatri.jpg";
import icon421 from "../../Assets/Teammembers/Snapchat-1199048726 - Vinay Saini.jpg";
import icon422 from "../../Assets/Teammembers/Yash Arora.jpg";
import icon423 from "../../Assets/Teammembers/20220821_151741 - Prikshit Anthal.jpg";
import icon424 from "../../Assets/Teammembers/Janardan photo1 - 022_Janardan.jpg";
import icon425 from "../../Assets/Teammembers/WhatsApp Image 2023-05-07 at 19.29.13 - Rithika G.jpeg";
import icon426 from "../../Assets/Teammembers/keshav.jpg";
import icon427 from "../../Assets/Teammembers/Anmoll.24c79311.jpg";
import icon428 from "../../Assets/Teammembers/manikya.png";
import icon429 from "../../Assets/Teammembers/Nimish.jpeg";
//3rd year

import icon31 from "../../Assets/Teammembers/sourav.jpg";
import icon33 from "../../Assets/Teammembers/aryan_photo - Aryan Kushan.jpg";
import icon34 from "../../Assets/Teammembers/IMG_20230304_184310 - Rahul Siloniya.jpg";
import icon35 from "../../Assets/Teammembers/IMG_20230718_170639 - Vivek Kumar.jpg";
import icon36 from "../../Assets/Teammembers/IMG_20230718_183706 - Asmita Singh.jpg";
import icon37 from "../../Assets/Teammembers/IMG-20230719-WA0002 - 070 Bhavya Gevariya.jpg";
import icon38 from "../../Assets/Teammembers/IMG20230506231648 - ME B6 6121 Aditya Dabral.jpg";
import icon39 from "../../Assets/Teammembers/profile - 3066 Vishesh Garg.jpeg";
import icon310 from "../../Assets/Teammembers/Screenshot_20230515-161138 - 051 MOHIT GUPTA.jpg";
import icon311 from "../../Assets/Teammembers/WhatsApp Image 2022-11-30 at 16.54.38 - ARYAN SHARMA.jpeg";
import icon313 from "../../Assets/Teammembers/Govind.jpg";
import icon314 from "../../Assets/Teammembers/yohannan.jpg";
import icon315 from "../../Assets/Teammembers/ujjawal tyagi.jpg";
import icon316 from "../../Assets/Teammembers/Umesh Kumar.jpeg";
import icon317 from "../../Assets/Teammembers/Sachin.jpeg";
import icon318 from "../../Assets/Teammembers/Rahul Meena.jpg";
import icon319 from "../../Assets/Teammembers/sugam.jpg";
import icon320 from "../../Assets/Teammembers/Harpreet singh.jpg";
//2nd year

import icon21 from "../../Assets/Teammembers/68E0CAD9-EDC5-4819-9E2E-B3BE230E1F6D - Shubhayu Sinha.jpeg";
import icon22 from "../../Assets/Teammembers/chirag.jpeg";
import icon24 from "../../Assets/Teammembers/12216103 - Mohit Chaudhary.jpg";
import icon25 from "../../Assets/Teammembers/RomitKumar.jpg";
import icon23 from "../../Assets/Teammembers/danish.jpg";
import icon26 from "../../Assets/Teammembers/mukul.jpg";
import icon27 from "../../Assets/Teammembers/IMG_8766 - Abhishek.jpeg";
import icon28 from "../../Assets/Teammembers/pritam.jpg";
import icon29 from "../../Assets/Teammembers/IMG_20230424_173056_927 - NEHA NEHA.jpg";
import icon210 from "../../Assets/Teammembers/IMG_20230718_175019 - Sahaj Srivastava.jpg";
import icon211 from "../../Assets/Teammembers/Vipul Goyal.jpg";
import icon212 from "../../Assets/Teammembers/IMG-20230718-WA0006~2 - Daksh Singh Chauhan.jpg";
import icon213 from "../../Assets/Teammembers/sanjana.jpg";
import icon214 from "../../Assets/Teammembers/linked in profile pic - Preetham A.jpeg";
import icon215 from "../../Assets/Teammembers/neha bhadu.jpeg";
import icon216 from "../../Assets/Teammembers/narayan.jpeg";
// import icon217 from "../../Assets/Teammembers/Screenshot_20230413_184643 - Anand Malaiya.jpg";
import icon218 from "../../Assets/Teammembers/somaa - SOMYA ARORA.jpg";
import icon219 from "../../Assets/Teammembers/hemant2.jpeg";
import icon220 from "../../Assets/Teammembers/photograph - Sarvagya Agrawal.jpg";
import icon221 from "../../Assets/Teammembers/sarthak anand.jpg";
import icon222 from "../../Assets/Teammembers/Akash.jpg";
import icon223 from "../../Assets/Teammembers/Abhay Rana.jpg";
import icon224 from "../../Assets/Teammembers/Jatin.jpg";
import icon225 from "../../Assets/Teammembers/ankit rathore.jpg";
import icon226 from "../../Assets/Teammembers/shivam raj.jpeg";
import icon227 from "../../Assets/Teammembers/ankit saini.jpeg";
import icon228 from "../../Assets/Teammembers/Divyansh.jpeg";
import icon229 from "../../Assets/Teammembers/ritesh.jpg";
import icon230 from "../../Assets/Teammembers/Naval Gurjar.jpeg";
import icon231 from "../../Assets/Teammembers/Ajay jangra.jpeg";
import icon232 from "../../Assets/Teammembers/Arjun Joshi.jpeg";
import icon233 from "../../Assets/Teammembers/priyanshu.jpg";
import icon234 from "../../Assets/Teammembers/atharva.jpg";
import icon235 from "../../Assets/Teammembers/yashGarg.jpeg";
import icon236 from "../../Assets/Teammembers/Sunny.jpg"
import icon237 from "../../Assets/Teammembers/sahil.jpg";
export const Alumni = [
  {
    id: 3,
    img: icon411,
    name: "Vaibhav Dharmani",
    Team: "Accelerons",
    metadata: {
      position1: "Ex-President",
    },
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/vaibhav-dharmani-aa91a7199",
    Email: "dharmani.vaibhav2001@gmail.com",
  },
  {
    id: 2,
    img: icon420,
    name: "Himanshu Khatri",
    Team: "Nitrox",
    metadata: {
      position1: "Ex-Vice-President",
      position2: "Captain",
    },
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/Hk2107",
    Email: "hkhatri2107@gmail.com",
    github: "https://github.com/Hk2107",
  },
  {
    id: 8,
    img: icon421,
    name: "Vinay Saini",
    Team: "Accelerons",
    metadata: {
      position1: "Ex-Secretary",
    },
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/vinay-saini-6a3044241",
    Email: "sainivinay013@gmail.com",
  },
  {
    id: 9,
    img: icon416,
    name: "Lisha Garg",
    Team: "Nitrox",
    metadata: {
      position1: "Ex-Secretary",
    },
    Department: "BMS,DAQ",
    LinkedIn: "https://www.linkedin.com/in/lisha-garg-61373a211",
    Email: "lisha317garg@gmail.com",
  },
  {
    id: 1,
    img: icon422,
    name: "Yash Arora",
    Team: "Accelerons",
    metadata: {
      position1: "Ex-Captain",
    },
    Department: "Engine, Transmission",
    LinkedIn: "https://www.linkedin.com/in/yash-arora-0b2027215/",
    Email: "yash_12017032@nitkkr.ac.in",
  },
  {
    id: 6,
    img: icon44,
    name: "Bhupeksh Kaushik",
    Team: "Accelerons",
    metadata: {
      position1: "Ex-Vice-Captain",
    },
    Department: "Mechanical",
    LinkedIn: "https://www.linkedin.com/in/bhupeksh-kaushik-360424222",
    Email: "kaushikbhupeksh@gmail.com",
  },
  {
    id: 28,
    img: icon427,
    name: "Anmol",
    Team: "Nitrox",
    metadata: {
      position1: "Ex-Vice-caption",
    },
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/anmol-chaudhary-278a22225/",
    Email: "anmolchaudhary854@gmail.com",
  },
  {
    id: 29,
    img: icon428,
    name: "Manikya",
    Team: "Accelerons",
    metadata: {
      position1: "Ex-EV-Head",
    },
    LinkedIn: "https://www.linkedin.com/in/10manikya/",
    Email: "NA@gmail.com",
  },
  {
    id: 7,
    img: icon41,
    name: "Parv Sarin",
    Team: "Nitrox",
    metadata: {
      position1: "Ex-EV-Head",
    },
    LinkedIn: "https://www.Linkedin.com/in/parv-sarin",
    Email: "Parvsarin01@gmail.com",
    github: "https://Github.com/BeastParv",
  },
  {
    id: 30,
    img: icon426,
    name: "Keshav Kumar Singh",
    Team: "Accelerons",
    metadata: {
      position1: "Ex-Treasure Head",
    },
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/keshav-k-singh/",
    Email: "kingkkumar2123@gmail.com",
  },
  {
    id: 11,
    img: icon414,
    name: "Kritika Agrawal",
    Team: "IT Team",
    metadata: {
      position1: "Ex-IT-Head",
    },
    LinkedIn: "https://www.linkedin.com/in/kritikaagra/",
    Email: "kritika_12017030@nitkkr.ac.in",
    github: "https://github.com/Kritikaagra",
  },
  {
    id: 13,
    img: icon412,
    name: "Navneet Raj",
    Team: "Nitrox",
    metadata: {
      position1: "Ex-DAQ Head",
    },
    Department: "DAQ, IT-Team",
    LinkedIn: "https://www.linkedin.com/in/navneet-raj-94395a182",
    Email: "navneetraj3695@gmail.com",
    github: "https://github.com/Navneet-Raj99",
  },
  {
    id: 5,
    img: icon417,
    name: "Aniruddha Dubey",
    Team: "Nitrox",
    metadata: {
      position1: "Ex-Procurement Head",
    },
    Department: "EV",
    LinkedIn: "https://www.linkedin.com/in/aniruddha-dubey-811074189/",
    Email: "aniruddhadubey4@gmail.com",
  },
  {
    id: 10,
    img: icon424,
    name: "Janardan Sharma",
    Team: "Accelerons",
    Department: "Motor and Motor Controller",
    LinkedIn: "https://www.linkedin.com/in/jd-sharma/",
    Email: "nishantjamdgni@gmail.com",
  },
  {
    id: 29,
    img: icon429,
    name: "Nimish Raman Mital",
    Team: "Nitrox",
    Department: "Vehicle Dynamics",
    LinkedIn: "http://linkedin.com/in/nimish-mital-58904a39",
    Email: "nimish_mital@yahoo.com",
    github: "",
  },
  {
    id: 4,
    img: icon419,
    name: "Himatri Noonwal",
    Team: "Accelerons",
    Department: "Battery",
    LinkedIn: "https://www.linkedin.com/in/himatri-noonwal/",
    Email: "him.noonwal2003@gmail.com",
    github: "https://github.com/Himatri",
  },
  {
    id: 16,
    img: icon45,
    name: "Garima Gupta",
    Team: "Nitrox",
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/garima-gupta-a11081221",
    Email: "ggupta2607@gmail.com",
  },
  {
    id: 17,
    img: icon48,
    name: "Deekshith Nayak",
    Team: "Accelerons",
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/deekshith-ramavath-419694254/",
    Email: "deekshithramavath33@gmail.com",
  },
  {
    id: 19,
    img: icon46,
    name: "Tanuj",
    Team: "Nitrox",
    Department: "Mechanical",
    LinkedIn: "https://www.linkedin.com/in/tanuj-yadav-49ab6a241",
    Email: "tanujkumar123001@gmail.com",
  },
  {
    id: 20,
    img: icon418,
    name: "Koilada bindu madhava",
    Team: "Accelerons",
    Department: "Chassis",
    LinkedIn: "https://www.linkedin.com/in/kbm0706/",
    Email: "bindumadhavakoilada@gmail.com",
    github: "https://github.com/dashboard",
  },
  {
    id: 21,
    img: icon413,
    name: "R Abinaya",
    Team: "Nitrox",
    Department: "Brakes",
    LinkedIn: "https://www.linkedin.com/in/r-abinaya-aa9a691a0",
    Email: "r_12016149@nitkkr.ac.in",
  },
  {
    id: 23,
    img: icon425,
    name: "Rithika Goddilla",
    Team: "Accelerons",
    Department: "Chassis",
    LinkedIn: "http://www.linkedin.com/in/rithika-goddilla-85a031230",
    Email: "goddillarithika22@gmail.com",
  },
  {
    id: 25,
    img: icon43,
    name: "Nishchal",
    Team: "Accelerons",
    Department: "Chassis",
    LinkedIn: "https://www.linkedin.com/in/nishchal2001/",
    Email: "nfulavanshi@gmail.com",
  },
  {
    id: 27,
    img: icon214,
    name: "Preetham",
    Team: "",
    Department: "VD,VI and Chassis",
    LinkedIn: "https://www.linkedin.com/in/preetham-a-289628225/",
    Email: "Preetham2532002@gmail.com",
    github: "https://github.com/EmulatedDragon",
  },
  {
    id: 15,
    img: icon42,
    name: "Keshav Mittal",
    Team: "IT Team",
    LinkedIn: "https://www.linkedin.com/in/keshav-mittal-ba26bb201/",
    Email: "keshav_12016018@nitkkr.ac.in",
  },
  {
    id: 14,
    img: icon423,
    name: "Prikshit Anthal",
    Team: "IT Team",
    LinkedIn: "https://www.linkedin.com/in/prikshit-anthal-485ab1206/",
    Email: "prikshitanthal@gmail.com",
    github: "https://github.com/Prikshit-anthal",
  },
  {
    id: 18,
    img: icon415,
    name: "Muskan Bidani",
    Team: "IT Team",
    LinkedIn: "https://www.linkedin.com/in/muskan-bidani-a25016209",
    Email: "muskan626bidani@gmail.com",
    github: "https://github.com/Muskan626bidani",
  },
  {
    id: 22,
    img: icon410,
    name: "ABHISHEK KUMAR",
    Team: "IT Team",
    LinkedIn: "https://www.linkedin.com/in/abhii30/",
    Email: "akumar300599@gmail.com",
    github: "https://github.com/abhii30",
  },
  {
    id: 24,
    img: icon47,
    name: "Sourabh Singh",
    Team: "IT Team",
    LinkedIn: "https://www.linkedin.com/in/sourabh2003/",
    Email: "Buckalsourabh@gmail.com",
    github: "https://github.com/sourabh707",
  },
];

export const Details4yr = [
  {
    id: 8,
    img: icon37,
    name: "Bhavya Gevariya",
    Team: "Nitrox",
    Department: "Transmission",
    metadata: {
      position1: "President",
    },
    LinkedIn: "https://www.linkedin.com/in/bhavya-gevariya-99183b220",
    Email: "Bhavyagevariya@gmail.com",
  },
  {
    id: 16,
    img: icon316,
    name: "Umesh Kumar",
    Team: "Accelerons",
    Department: "Transmission",
    metadata: {
      position1: "Vice President",
    },
    LinkedIn: "https://www.linkedin.com/in/umesh-kumar-4532551a6/",
    Email: "12116024@nitkkr.ac.in",
  },
  {
    id: 9,
    img: icon38,
    name: "Aditya Dabral",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    metadata: {
      position1: "Secretary",
    },
    LinkedIn: "https://www.linkedin.com/in/aditya-dabral-294a6523a",
    Email: "adityas.dabral@gmail.com",
  },
  {
    id: 7,
    img: icon36,
    name: "Asmita Singh",
    Team: "Nitrox",
    Department: "Brakes",
    metadata: {
      position1: "Secretary",
    },
    LinkedIn: "https://www.linkedin.com/in/asmita-singh-7657a8261/",
    Email: "asmita0782@gmail.com",
  },
  {
    id: 17,
    img: icon317,
    name: "Sachin Kumar",
    Team: "Accelerons",
    Department: "Brakes",
    metadata: {
      position1: "Captain",
    },
    LinkedIn: "https://www.linkedin.com/in/sachin-kumar-a827b7258/",
    Email: "sachinphalswal19@gmail.com",
  },
  {
    id: 3,
    img: icon33,
    name: "Aryan Kushan",
    Team: "Nitrox",
    Department: "Vehicle Dynamics",
    metadata: {
      position1: "Captain",
    },
    LinkedIn: "https://www.linkedin.com/in/aryan-kushan-048415250",
    Email: "kushan8nov@gmail.com",
  },
  {
    id: 15,
    img: icon314,
    name: "Yohannan Jezaniah",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    metadata: {
      position1: "Vice Captain",
    },
    LinkedIn: "https://www.linkedin.com/in/yohannan-jezaniah-9436aa20b/",
    Email: "12116157@nitkkr.ac.in",
  },
  {
    id: 12,
    img: icon311,
    name: "Aryan Sharma",
    Team: "Nitrox",
    Department: "Vehicle Dynamics",
    metadata: {
      position1: "Vice Captain",
    },
    LinkedIn: "https://www.linkedin.com/in/aryan-sharma-950684229",
    Email: "sharmaaryanjmu@gmail.com",
  },
  {
    id: 19,
    img: icon319,
    name: "Sugam Tripathi",
    Team: "Nitrox",
    Department: "Electrical",
    metadata: {
      position1: "EV Head",
    },
    LinkedIn: "https://www.linkedin.com/in/sugam-tripathi-6aa67b226/",
    Email: "NA@gmail.com",
  },
  {
    id: 1,
    img: icon31,
    name: "Sourav",
    Team: "Accelerons",
    Department: "Transmission",
    metadata: {
      position1: "Student Program Chairperson",
    },
    LinkedIn: "https://www.linkedin.com/in/sourav-kumar-691270284",
    Email: "101souravkumar@gamil.com",
  },
  {
    id: 14,
    img: icon313,
    name: "Govind",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    metadata: {
      position1: "Student Program Chairperson",
    },
    LinkedIn: "https://www.linkedin.com/in/govind-kumar-mahawar-955570259/",
    Email: "govindmahawar960@gmail.com",
  },
  {
    id: 6,
    img: icon35,
    name: "Vivek Kumar",
    Team: "Nitrox",
    metadata:{
      position1:"Treasurer"
    },
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/vivek-kumar-787b96237",
    Email: "vicky746586@gmail.com",
  },
  {
    id: 18,
    img: icon318,
    name: "Rahul Meena",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    metadata: {
      position1: "Media Head",
      position2:"Procurement Head"
    },
    LinkedIn: "https://www.linkedin.com/in/rahul-kumar-meena-797138256/",
    Email: "rahulkm4723@gmail.com",
  },
  {
    id: 19,
    img: icon320,
    name: "Harpreet Singh",
    Team: "Nitrox",
    metadata:{
      position1:"Media Head"
    },
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/harpreet-singh-16bb83286/",
    Email: "NA@gmail.com",
  },
  {
    id: 4,
    img: icon315,
    name: "Ujjawal Tyagi",
    Team: "IT Team",
    metadata: {
      position1: "IT Head",
    },
    LinkedIn: "https://www.linkedin.com/in/ujjawal-tyagi-943762213/",
    Email: "",
  },
  {
    id: 5,
    img: icon34,
    name: "Rahul Siloniya",
    Team: "IT Team",
    metadata: {
      position1: "IT Head",
    },
    LinkedIn: "https://www.linkedin.com/in/rahul-siloniya-64776a231",
    Email: "rsiloniya@gmail.com",
    github: "https://github.com/rahulsiloniya",
  },
  {
    id: 10,
    img: icon39,
    name: "Vishesh Garg",
    Team: "IT Team",
    LinkedIn: "www.linkedin.com/in/vishesh-garg-07580622b",
    Email: "gargvishesh95885@gmail.com",
    github: "https://github.com/VisheshGrg",
  },
  {
    id: 11,
    img: icon310,
    name: "Mohit Gupta",
    Team: "IT Team",
    LinkedIn: "https://www.linkedin.com/in/guptamohit321",
    Email: "mg550286@gmail.com",
  },
];

export const Details3yr = [
  {
    id: 19,
    img: icon219,
    name: "Hemant Rao",
    Team: "Accelerons",
    Department: "Battery,Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/hemant-rao-34217a263/",
    Email: "raohemant801@gmail.com",
  },
  {
    id: 1,
    img: icon21,
    name: "Shubhayu Sinha",
    Team: "Nitrox",
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/shubhayu-sinha-9a5053264",
    Email: "shubhayu.sinha@gmail.com",
  },
  {
    id: 5,
    img: icon23,
    name: "Danish Goyal",
    Team: "Nitrox",
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/danish-goyal-94bb80269/",
    Email: "danishgoyaljind15@gmail.com",
  },
  {
    id: 3,
    img: icon24,
    name: "Mohit Chaudhary",
    Team: "Nitrox",
    Department: "Vehicle Dynamics",
    LinkedIn: "",
    Email: "mohitchaudhary1325@gmail.com",
  },
  {
    id: 8,
    img: icon28,
    name: "Pritam Beriwal",
    Team: "Nitrox",
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/pritam-beriwal/",
    Email: "pritamberiwal91@gmail.com",
  },
  {
    id: 21,
    img: icon221,
    name: "Sarthak Anand",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    LinkedIn: "",
    Email: "sarthakanand2003@gmail.com",
  },
  {
    id: 12,
    img: icon212,
    name: "Daksh Singh Chauhan",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/daksh-singh-chauhan-03ab47255",
    Email: "dakshsinghc27@gmail.com",
    github: "https://github.com/DakshKing2003",
  },
  {
    id: 29,
    img: icon229,
    name: "Ritesh Nirmal",
    Team: "Accelerons",
    Department: "Vehicle Dynamics",
    LinkedIn: "https://www.linkedin.com/in/ritesh-kumar-nirmal-3a745425b/",
    Email: "NA@gmail.com",
  },
  {
    id: 4,
    img: icon25,
    name: "Romit Kumar",
    Team: "Nitrox",
    Department: "Brakes",
    LinkedIn: "https://www.linkedin.com/in/romit-kumar-164015264/",
    Email: "romitkumar066@gmail.com",
  },
  {
    id: 23,
    img: icon223,
    name: "Abhay Rana",
    Team: "Accelerons",
    Department: "Brakes",
    LinkedIn: "https://www.linkedin.com/in/abhay-rana-734bb724a/",
    Email: "abhayrana13a@gmail.com",
  },
  {
    id: 6,
    img: icon26,
    name: "Mukul Verma",
    Team: "Nitrox",
    Department: "Brakes",
    LinkedIn: "https://www.linkedin.com/in/mukul-verma-590113267/",
    Email: "mukulv842@gmail.com",
  },
  {
    id: 28,
    img: icon228,
    name: "Divyansh Garg",
    Team: "Nitrox",
    Department: "Brakes",
    LinkedIn: "https://www.linkedin.com/in/divyansh-garg-179000286/",
    Email: "bharatgarg685@gmail.com",
  },
  {
    id: 30,
    img: icon230,
    name: "Naval Gurjar",
    Team: "Accelerons",
    Department: "Brakes",
    LinkedIn: "",
    Email: "NA@gmail.com",
  },
  {
    id: 34,
    img: icon234,
    name: "Atharva Mahajan",
    Team: "Nitrox",
    Department: "Brakes",
    LinkedIn: "https://www.linkedin.com/in/atharva-mahajan-2aa7511b0/",
    Email: "mahajanatharva43@gmail.com",
  },
  {
    id: 16,
    img: icon216,
    name: "Lakshmi narayan",
    Team: "Nitrox",
    Department: "Transmission",
    LinkedIn: "",
    Email: "12216076@nitkkr.ac.in",
  },
  {
    id: 10,
    img: icon210,
    name: "Sahaj Srivastava",
    Team: "Nitrox",
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/sahaj-kumar-6b469325a/",
    Email: "imssahaj@gmail.com",
  },
  {
    id: 31,
    img: icon231,
    name: "Ajay Jangra",
    Team: "Accelerons",
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/ajay-jangra-67a499289",
    Email: "ajayjangra2403@gmail.com",
  },
  {
    id: 33,
    img: icon233,
    name: "Priyanshu",
    Team: "Accelerons",
    Department: "Transmission",
    LinkedIn: "https://www.linkedin.com/in/priyanshu-priyanshu-879377293/",
    Email: "12216052@nitkkr.ac.in",
  },
  {
    id:37,
    img:icon237,
    name:"Sahil",
    Team:"Accelerons",
    Department:"Transmission",
    LinkedIn:"https://www.linkedin.com/in/sahil-meena-160628260/",
    Email:"sahilghusinga@gmail.com",
  },
  {
    id: 24,
    img: icon224,
    name: "Jatin Yadav",
    Team: "Accelerons",
    Department: "Electrical",
    LinkedIn: "https://www.linkedin.com/in/jatin-yadav-63b4ba257/",
    Email: "jatinkhairwal2003@gmail.com",
  },
  {
    id: 7,
    img: icon27,
    name: "Abhishek",
    Team: "Accelerons",
    Department: "Electrical",
    LinkedIn: "https://www.linkedin.com/in/abhi-badgujar-882bb124b/",
    Email: "abhi.abhishek.badgujar@gmail.com",
  },
  {
    id: 2,
    img: icon22,
    name: "Chirag jangra",
    Team: "Nitrox",
    Department: "Electrical",
    LinkedIn: "",
    Email: "chiragjangra268@gmail.com",
  },
  {
    id: 32,
    img: icon232,
    name: "Arjun Joshi",
    Team: "Nitrox",
    Department: "Electrical",
    LinkedIn: "",
    Email: "",
  },
  {
    id: 20,
    img: icon220,
    name: "Sarvagya Agrawal",
    Team: "Accelerons",
    Department: "Electrical",
    LinkedIn: "https://www.linkedin.com/in/sarvagya-agrawal-5371a3258/",
    Email: "12214084@nitkkr.ac.in",
  },
  {
    id: 22,
    img: icon222,
    name: "Akash",
    Team: "Accelerons",
    Department: "DAQ",
    LinkedIn: "https://www.linkedin.com/in/akash-jindhar-a97648294",
    Email: "akashjindhar@gmail.com",
  },
  {
    id: 11,
    img: icon211,
    name: "Vipul Goyal",
    Team: "Nitrox",
    Department: "DAQ,IT",
    LinkedIn: "https://www.linkedin.com/in/vipul-goyal-439210257",
    Email: "vipulgoyal776@gmail.com",
    github: "https://github.com/VipulGoyal95",
  },
  //   {
  //     id: 17,
  //     img: icon217,
  //     name: "Anand Malaiya",
  //     Team: "Accelerons",
  //     LinkedIn:"",
  //     Email:"anandmalaiya474@gmail.com"
  //   },
  {
    id: 18,
    img: icon218,
    name: "Somya Arora",
    Team: "Accelerons",
    Department: "DAQ",
    LinkedIn: "https://www.linkedin.com/in/somya-arora24/",
    Email: "somya24arora@gmail.com",
  },
  {
    id: 25,
    img: icon225,
    name: "Ankit Rathore",
    Team: "Nitrox",
    Department: "DAQ",
    LinkedIn: "https://www.linkedin.com/in/ankit-rathore-19031a284/",
    Email: "12215078@nitkkr.ac.in",
  },
  {
    id: 35,
    img: icon235,
    name: "Yash Garg",
    Team: "IT Team",
    Department: "",
    LinkedIn: "https://www.linkedin.com/in/yash-garg-566417281",
    Email: "Ygarg423@gmail.com",
  },
  {
    id: 36,
    img: icon236,
    name: "Sunny",
    Team: "IT Team",
    Department: "",
    LinkedIn: "https://www.linkedin.com/in/sunny-sabharwal-761009289?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app ",
    Email: "sunnysabharwal2233@gmail.com",
  }
];
