export const crowdFundingData = [
  ['Yogesh Virmani', '30000'],
  ['Mohit Mahajan', '25000'],
  ['Poshak Batra', '20000'],
  ['Hardik Sachdeva', '20000'],
  ['Rajat Singla', '19999'],
  ['Amit', '19999'],
  ['Soumya Roop', '16172'],
  ['Devesh Bhasin', '16000'],
  ['Rupasa Karmakar', '15000'],
  ['Keshav Sharma', '15000'],
  ['Hardeep Jagde ', '12160'],
  ['Desh Ratna', '11000'],
  ['Ashish Aggarwal', '10000'],
  ['Arpan Harit', '10000'],
  ['Gaurav Chaudhary', '10000'],
  ['Akshay Singh', '10000'],
  ['Akshay', '10000'],
  ['Tony Thomas Philips', '10000'],
  ['Sridhar Raman', '10000'],
  ['Shashwat Luthra', '10000'],
  ['Manoj Sardana', '10000'],
  ['Vinod Nair', '10000'],
  ['Ritesh Khurana', '10000'],
  ['Parag Birmiwal', '10000'],
  ['Yogesh Sharma', '10000'],
  ['Puneet Talwar', '10000'],
  ['Rushikesh Bhujpal', '10000'],
  ['Tanuj Batra', '10000'],
  ['Prathamesh Apte', '10000'],
  ['Sushant Aggrawal', '7500'],
  ['Ankit Kumar', '7000'],
  ['Krishna Kumar Tandon', '5001'],
  ['Varun Mittal', '5000'],
  ['Abhishek Dhanda', '5000'],
  ['Amit Sharma', '5000'],
  ['Faraz Khan', '5000'],
  ['Rahul Kamboj', '5000'],
  ['Hitanshu Monga', '5000'],
  ['Anil Raina', '5000'],
  ['Manish Kumar', '5000'],
  ['Manthan Patel', '5000'],
  ['Jodhjeet Singh', '5000'],
  ['Utsav Mishra', '5000'],
  ['Mohit Kardam', '5000'],
  ['Sandeep Chopra', '5000'],
  ['Shyam Jha', '5000'],
  ['Suman Tandon', '5000'],
  ['Meena Tandon', '5000'],
  ['Sankalp Arora', '5000'],
  ['Ranvinder Bisht', '5000'],
  ['Rahul Lamba', '5000'],
  ['Amit Rawat', '5000'],
  ['Rajshekhar Bandi', '3000'],
  ['Harsh Chaudhary ', '2500'],
  ['Unknown', '2500'],
  ['Sidhant Gupta', '2001'],
  ['Soni Tandon', '2001'],
  ['Sushant Kumar', '2000'],
  ['Ashutosh Joshi', '2000'],
  ['Sehej Bansal', '2000'],
  ['Theja Varikuti', '2000'],
  ['Anindya', '2000'],
  ['Aarti Dangi', '2000'],
  ['Rohit Singla', '2000'],
  ['Unknown', '2000'],
  ['Chhavi Jain', '2000'],
  ['Kritika Dhar', '2000'],
  ['Praveen Choudhary', '2000'],
  ['Kuldeep Singh', '2000'],
  ['Vaibhav Nagar', '2000'],
  ['Unknown', '1500'],
  ['Unknown', '1111'],
  ['Sachin Saini', '1100'],
  ['Ishara Mahapatra', '1000'],
  ['Hanish Soron', '1000'],
  ['Sachin Sabarwal', '1000'],
  ['Shobhit Kumar', '1000'],
  ['Pankaj Nandalal', '1000'],
  ['Vikas Choudhary', '1000'],
  ['Ayush Meheshwari', '1000'],
  ['Jitendra Kumar', '1000'],
  ['Unknown', '1000'],
  ['Ashish Kumar', '1000'],
  ['Yash Mittal', '1000'],
  ['Tripti Tiple', '1000'],
  ['Kunal Hasija', '500'],
  ['Rajesh Shah', '500'],
  ['Anil Kumar Agrawal', '500'],
  ['Lakshay Hurria', '400'],
  ['Ashish Sharma', '200'],
  ['SAE', '101']
]